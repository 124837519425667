import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PublicLayout from '@/components/frontend/PublicLayout';
import { fetchNavigations } from '@/lib/content-api';
import { ExtendedGetStaticPropsContext } from '@/lib/content-api/types';
import getRevalidateValue from '@/lib/getRevalidateValue';

const ListingNotFound = dynamic(
  () => import('@/components/entry-templates/ListingNotFound'),
);
const ErrorPage = dynamic(
  () => import('@/components/entry-templates/ErrorPage'),
);

const NotFound = () => {
  const { t } = useTranslation('errors');
  const { asPath } = useRouter();
  const [basePath] = asPath.split('/').filter(Boolean);

  switch (basePath) {
    case 'osta':
      return <ListingNotFound />;

    default:
      return (
        <ErrorPage
          subHeading={t('page_not_found_subtitle', 'Sivua ei löytynyt')}
          title={t('page_not_found', 'Metsään meni')}
        />
      );
  }
};

NotFound.layout = PublicLayout;

export const getStaticProps = async (
  context: ExtendedGetStaticPropsContext,
) => {
  const { locale = 'fi', previewData } = context;
  const navigationsP = fetchNavigations({
    lang: locale,
    previewData,
  });

  // prepare promise for translations
  const i18nPromise = serverSideTranslations(locale, ['frontoffice', 'errors']);

  const [i18nProps, navigations] = await Promise.all([
    i18nPromise,
    navigationsP,
  ]);

  return {
    props: {
      ...i18nProps,
      navigations: navigations || null,
    },
    revalidate: getRevalidateValue(240),
  };
};

export default NotFound;
